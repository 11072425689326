.alert {
  position: relative;
  margin: -20px auto;
  top: 20px;
}

.alert .alert-notification {
  background-color: var(--red);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  top: -53px;
  left: 41px;
}

.alert .alert-notification span {
  color: var(--white);
  font-size: 12px;
  font-weight: bold;
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 20px;
}