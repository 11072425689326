* {
  --primary-color: rgba(0, 22, 51, 0.7) !important;
  --secondary-color: #001633 !important;
  --special-color-1: #001633 !important;
  --special-color-2: rgba(0, 22, 51, 0.05) !important;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

h2 {
  color: #535374;
}

h3 {
  font-size: 20px;
}

.special-color-1 {
  color: var(--special-color-1);
}
.special-color-2 {
  color: var(--special-color-2);
}

.bg-special-color-1 {
  background-color: var(--special-color-1);
}
.bg-special-color-2 {
  background-color: var(--special-color-2);
}

.button-carbon {
  background-color: #4fbc9d !important;
  border-color: #4fbc9d !important;
}

.button-outline-red {
  border-color: var(--red) !important;
}

.button-outline-red > span {
  color: var(--red) !important;
}

@media screen and (min-width: 992px) {
  .background-signin {
    background-image: url("images/background-signin.jpg");
    background-size: cover;
    background-position: 50% 75%;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.background-signin-v2 {
  background-image: url("images/background-signin.jpg");
  background-size: cover;
  background-position: 50% 75%;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.background-test {
  background-image: url("images/test.png");
  background-size: 100% 100%;
  padding-left: 80px;
  padding-top: 190px;
  padding-bottom: 120px;
  padding-right: 140px;
}

.Dashboard-content {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.col_full_height {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-file-viewer {
  z-index: 100;
}

.container-file-viewer > div {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.container-file-viewer > div > div {
  display: flex;
}

.contact-dropzone > section {
  width: 100% !important;
  height: 110px !important;
}

.popup-modal h4 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.3px;
  margin: 0 auto 20px;
  width: 70%;
}

.popup-modal p {
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.3px;
  margin: 0 auto;
  width: 70%;
}

.popup-modal p:nth-of-type(2) {
  font-weight: bold;
}

.popup-modal .button-container {
  margin: auto;
  margin-top: 40px;
}

.popup-modal .button-container.small {
  width: 150px;
}

.popup-modal .button-container.medium {
  width: 220px;
}

.popup-modal .button-container.large {
  width: 300px;
}

.popup-modal .button-container.xlarge {
  width: 400px;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
